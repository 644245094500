<template>
  <aside>
      <section
        class="col-sm-12 text-left"
        style="padding: 0px"

        :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
        <h4 class="section-header" >
          <i class="tim-icons icon-light-3"
             style="font-size: 12px"
             :style="{color: this.primaryColor, border: `2px solid ${this.secondaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
          KEEP EXPLORING
        </h4>
        <DynamicLinks :links="links"
                      :size="size"
                      :style="{ background: `linear-gradient(45deg, black, ${this.primaryColor})` }"
        ></DynamicLinks>

      </section>
      <section
        class="col-sm-12 text-left"
        style="padding: 0px"
        :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
        <img v-lazy="this.djImage" alt="tulum dj"
             width="50px"
             height="100px" class="pull-right">
        <h4 class="section-header" >
          <i class="tim-icons icon-bulb-63"
             style="font-size: 12px"
             :style="{color: this.primaryColor, border: `2px solid ${this.secondaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
           WHAT's HOT
        </h4>
        <hr style="height: 2px" :style="{ backgroundColor: this.primaryColor }"/>
        <MiniCard
          title="Tulum NYE 2025"
          text="HIGH SEASON"
          link="/tulum-new-year-festival-2024"
          image="https://imgix.cosmicjs.com/d38e0f20-9a39-11ee-b34c-99d1d89e42eb-aqua-eye.png"
        ></MiniCard>
        <MiniCard
          title="Vagalume On Fire"
          text="Winter Season"
          link="/tulum-clubs/vagalume-tulum"
          image="https://imgix.cosmicjs.com/7dd08f80-63fd-11ee-b88a-b3386968dc36-image.png"
        ></MiniCard>
        <MiniCard
          title="Tulum Festivals"
          text="Zamna Tulum"
          link="/brands/zamna-tulum"
          image="https://imgix.cosmicjs.com/47039b80-cc42-11ed-a5c3-fb6dd2500767-afterlifetulum.jpg"
        ></MiniCard>
        <div class="card text-center centered" style="padding: 30px">
          <img v-lazy="tulumPartyLogo" height="15" width="105" class="centered" alt="tulum party logo">
          <small style="font-weight: lighter;font-size: 10px">PLAN A | SIDE B</small>
        </div>
      </section>
  </aside>
</template>
<script>
import DynamicLinks from "@/components/DynamicLinks"
import MiniCard from '@/components/Cards/MiniCard'
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog"

export default {
  name: 'ClubsSmartMenu',
  data: () => {
    return {
      clicked: false,
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      tpLogo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      tulumPartyLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png?w=105&h=15&fit=clamp'
    }
  },
  components: {DynamicLinks, MiniCard, RsvpViaDialog},
  props: {
    links: {
      type: Array,
      required: true
    },
    size: {
      type: String,
    },
    logo: {
      type: String,
      default: ''
    },
    primaryColor: {
      type: String,
      default: '#323232'
    },
    secondaryColor: {
      type: String,
      default: '#4c4c4c'
    },
    topSectionName: {
      type: String,
      default: 'GET IN TOUCH'
    },
    ctaText: {
      type: String,
      default: 'Hit us up!'
    },
  }
}
</script>
